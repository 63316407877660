import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let isValid = /^\d{4}-\d{2}-\d{2}$/.test(value);
    if (isValid) {
      const [year, month, day] = value.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      isValid = date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
    } else {
      const date: NgbDateStruct = value;
      if (!date || !date.year || !date.month || !date.day) {
        return { invalidDate: { value } };
      }
      isValid = !isNaN(Date.UTC(date.year, date.month - 1, date.day));
    }
    return isValid ? null : { invalidDate: { value } };
  };
}
