import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileHolder } from '../image-upload/image-upload.component';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Dog, DogPedigree, HealthCategoriesResponse, PedigreeNameRecord } from '../models/dog.model';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DogService } from '../services/dog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { TokenStorageService } from '../services/token-storage.service';
import { ModalService } from '../services/modal.service';
import { dateValidator } from '../shared/DateValidator';


@Component({
  selector: 'app-update-dog-form',
  templateUrl: './update-dog-form.component.html',
  styleUrls: ['./update-dog-form.component.scss']
})
export class UpdateDogFormComponent implements OnInit, AfterViewInit, OnDestroy {

  updateDogForm: UntypedFormGroup;
  aUser: any;
  showSpinner = false;
  submitted = false;
  filePaths:  string[];
  files: FileHolder[] = [];
  photoFilePath = ''
  uploadFailed = false;
  missingPedigreeDataExists = false;
  dogCertIds = [];
  dogHealth = '';
  dogCallName = '';
  dogTitles = '';
  dogGender = '';
  dogDOB = '';
  dogDOD = '';
  dogCoatColor = '';
  dogBreederName = '';
  dogOwnerName = '';
  customErrorMessage = '';
  private unsubscribe$ = new Subject<void>();
  private unsubscribe2$ = new Subject<void>();
  sub: Subscription | undefined;
  sub2: Subscription | undefined;
  selectedDogInfo: Dog;
  selectedDogPedigree: DogPedigree;
  selectedDogTitles: string | undefined = '';
  selectedDogHealth: string | undefined = '';
  errorModalRef: NgbModalRef | undefined;
  dogName = 'Unknown';
  dogSireName = 'Unknown';
  dogDamName = 'Unknown';
  dogSireSireName = 'Unknown';
  dogSireDamName = 'Unknown';
  dogSireSireSireName = 'Unknown';
  dogSireSireDamName = 'Unknown';
  dogSireDamSireName = 'Unknown';
  dogSireDamDamName = 'Unknown';
  dogDamSireName = 'Unknown';
  dogDamDamName = 'Unknown';
  dogDamSireSireName = 'Unknown';
  dogDamSireDamName = 'Unknown';
  dogDamDamSireName = 'Unknown';
  dogDamDamDamName = 'Unknown';
  showHidePedigreeButtonName = 'Show Pedigree';
  pedigreePanelOpen = false;
  dogId = -1;
  healthCategories: HealthCategoriesResponse = {};
  healthCategoryNames: (string | undefined)[] | undefined = [];
  healthCategoryOptionNames: (string | undefined)[] | undefined = [];
  healthCategoryDeathOptionNames: (string | undefined)[] | undefined = [];
  dateOfBirthModel: NgbDateStruct | undefined;
  dateOfDeathModel: NgbDateStruct | undefined;
  minDate = { year: 1912, month: 1, day: 1 };
  today = new Date();
  maxDate={year:this.today.getFullYear(),month: this.today.getMonth()+1, day: this.today.getDate()}

  constructor(private tokenService: TokenStorageService,
              private fb: UntypedFormBuilder,
              private modalService: ModalService,
              private dogService: DogService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.updateDogForm = new UntypedFormGroup({});
    this.aUser = this.tokenService.getUser();

    this.filePaths = [];
    this.files = [];
    this.selectedDogInfo = { registry_info: [] };
    this.selectedDogPedigree = {acl3: 0, acl4: 0, pedigree: {}};
  }

  get form1() {
    return this.updateDogForm.controls;
  }
  ngOnInit(): void {
    if (!this.tokenService.isLoggedIn) {
      // can't get here if you're not logged in
      this.router.navigateByUrl('/search');
    }
    this.updateDogForm = this.fb.group({
      submitterName: [{value: '', disabled: true}, Validators.required],
      submitterEmail: [{value: '', disabled: true}, Validators.email],
      submitterMemberId: ['', Validators.required],
      dogId: ['', Validators.required],
      dogNotes: [''],
      callName: [false],
      sireDam: [false],
      health: [false],
      addHealth: [false],
      healthCategory: [{value: '', disabled: true}],
      healthCategoryOption: [{value: '', disabled: true}],
      healthConditionPublic: [{value: 0, disabled: true}],
      titles: [false],
      death: [{value: 0, disabled: true}],
      dob: [false],
      dod: [false],
      dateOfBirth: [{value: '', disabled: true}],
      dateOfDeath: [{value: '', disabled: true}],
      other: [false],
      otherDetails: [{value: '', disabled: true}]
    });
    this.sub2 = this.dogService.getHealthCategories()
      .subscribe((res) => {
        this.healthCategories = res;
        this.healthCategoryNames = this.healthCategories.health?.map((acat) => acat.title);
      });

    this.sub = this.activatedRoute.paramMap
      .subscribe(params => {
        const theDog = params.get('dogId');
        if ((theDog !== null) && (+theDog > 0)) {
          this.dogId = +theDog;
          console.log('got dog: ' + this.dogId);
          this.showSpinner = true;
          forkJoin({
            dogData: this.dogService.getDogInfo(this.dogId),
            dogPedigree: this.dogService.getDogPedigree(this.dogId, 3),
            dogHealth: this.dogService.getDogHealthString(this.dogId),
            dogAwards: this.dogService.getDogTitles(this.dogId)
          })
            .subscribe(
              (data) => {
                this.showSpinner = false;
                if (data.dogData.hasOwnProperty('dog_id')) {
                  this.selectedDogInfo = data.dogData;
                  this.dogCoatColor = this.selectedDogInfo?.coat_color?.coat_color_description ?? 'Unknown';
                  const datenumeric = this.selectedDogInfo?.date_of_birth ?? '1/1/1970';
                  const dateDate = DateTime.fromISO(datenumeric);
                  this.dogDOB = dateDate.toUTC().toISODate() ?? 'Unknown';
                  const datenumeric2 = this.selectedDogInfo?.date_of_death ?? '';
                  if (datenumeric2 !== '') {
                    const dateDate = DateTime.fromISO(datenumeric2);
                    this.dogDOD = dateDate.toUTC().toISODate() ?? '';
                  } else {
                    this.dogDOD = '';
                  }
                  this.dogGender = this.selectedDogInfo?.sex == 'M' ? 'Male' : 'Female' ?? 'Unknown';
                }
                this.selectedDogPedigree = data.dogPedigree;
                this.missingPedigreeDataExists = false;
                if ((this.selectedDogPedigree.pedigree!= null) && (this.selectedDogPedigree.pedigree.dog != null)) {
                  const dogRecord = this.selectedDogPedigree.pedigree.dog;
                  this.dogName = this.createDogName(dogRecord.name, dogRecord.health, dogRecord.callName);
                  if (dogRecord.hasOwnProperty('sire')) {
                    const dogSireRecord = dogRecord.sire;
                    this.dogSireName = this.createDogName(dogSireRecord.name, dogSireRecord.health, dogSireRecord.callName);
                    if (dogSireRecord.hasOwnProperty('sire')) {
                      const dogSireSireRecord = dogSireRecord.sire;
                      this.dogSireSireName = this.createDogName(dogSireSireRecord.name, dogSireSireRecord.health, dogSireSireRecord.callName);
                      if (dogSireSireRecord.hasOwnProperty('sire')) {
                        const dogSireSireSireRecord = dogSireSireRecord.sire;
                        this.dogSireSireSireName = this.createDogName(dogSireSireSireRecord.name, dogSireSireSireRecord.health, dogSireSireSireRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                      if (dogSireSireRecord.hasOwnProperty('dam')) {
                        const dogSireSireDamRecord = dogSireSireRecord.dam;
                        this.dogSireSireDamName = this.createDogName(dogSireSireDamRecord.name, dogSireSireDamRecord.health, dogSireSireDamRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                    } else {
                      this.missingPedigreeDataExists = true;
                    }
                    if (dogSireRecord.hasOwnProperty('dam')) {
                      const dogSireDamRecord = dogSireRecord.dam;
                      this.dogSireDamName = this.createDogName(dogSireDamRecord.name, dogSireDamRecord.health, dogSireDamRecord.callName);
                      if (dogSireDamRecord.hasOwnProperty('sire')) {
                        const dogSireDamSireRecord = dogSireDamRecord.sire;
                        this.dogSireDamSireName = this.createDogName(dogSireDamSireRecord.name, dogSireDamSireRecord.health, dogSireDamSireRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                      if (dogSireDamRecord.hasOwnProperty('dam')) {
                        const dogSireDamDamRecord = dogSireDamRecord.dam;
                        this.dogSireDamDamName = this.createDogName(dogSireDamDamRecord.name, dogSireDamDamRecord.health, dogSireDamDamRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                    } else {
                      this.missingPedigreeDataExists = true;
                    }
                  } else {
                    this.missingPedigreeDataExists = true;
                  }
                  if (dogRecord.hasOwnProperty('dam')) {
                    const dogDamRecord = dogRecord.dam;
                    this.dogDamName = this.createDogName(dogDamRecord.name, dogDamRecord.health, dogDamRecord.callName);
                    if (dogDamRecord.hasOwnProperty('sire')) {
                      const dogDamSireRecord = dogDamRecord.sire;
                      this.dogDamSireName = this.createDogName(dogDamSireRecord.name, dogDamSireRecord.health, dogDamSireRecord.callName);
                      if (dogDamSireRecord.hasOwnProperty('sire')) {
                        const dogDamSireSireRecord = dogDamSireRecord.sire;
                        this.dogDamSireSireName = this.createDogName(dogDamSireSireRecord.name, dogDamSireSireRecord.health, dogDamSireSireRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                      if (dogDamSireRecord.hasOwnProperty('dam')) {
                        const dogDamSireDamRecord = dogDamSireRecord.dam;
                        this.dogDamSireDamName = this.createDogName(dogDamSireDamRecord.name, dogDamSireDamRecord.health, dogDamSireDamRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                    } else {
                      this.missingPedigreeDataExists = true;
                    }
                    if (dogDamRecord.hasOwnProperty('dam')) {
                      const dogDamDamRecord = dogDamRecord.dam;
                      this.dogDamDamName = this.createDogName(dogDamDamRecord.name, dogDamDamRecord.health, dogDamDamRecord.callName);
                      if (dogDamDamRecord.hasOwnProperty('sire')) {
                        const dogDamDamSireRecord = dogDamDamRecord.sire;
                        this.dogDamDamSireName = this.createDogName(dogDamDamSireRecord.name, dogDamDamSireRecord.health, dogDamDamSireRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                      if (dogDamDamRecord.hasOwnProperty('dam')) {
                        const dogDamDamDamRecord = dogDamDamRecord.dam;
                        this.dogDamDamDamName = this.createDogName(dogDamDamDamRecord.name, dogDamDamDamRecord.health, dogDamDamDamRecord.callName);
                      } else {
                        this.missingPedigreeDataExists = true;
                      }
                    } else {
                      this.missingPedigreeDataExists = true;
                    }
                  } else {
                    this.missingPedigreeDataExists = true;
                  }
                } else {
                  this.missingPedigreeDataExists = true;
                }

                if (data.dogHealth.hasOwnProperty('health')) {
                  this.selectedDogHealth = data.dogHealth.health;
                } else {
                  this.selectedDogHealth = '';
                }
                if (data.dogAwards.hasOwnProperty('titleString')) {
                  this.selectedDogTitles = data.dogAwards.titleString;
                } else {
                  this.selectedDogTitles = '';
                }
              },
              (error) => {
                setTimeout(() => {
                  this.showSpinner = false;
                  this.modalService.open('modal-update-error');
                }, 500);
              }
            )
        } else {
          // redirect to search
          this.router.navigateByUrl('/search');
        }
      })

  }
  ngAfterViewInit() {
    const nameField = this.updateDogForm.get('submitterName');
    nameField?.patchValue(this.aUser.firstName + ' ' + this.aUser.lastName);
    const emailField2 = this.updateDogForm.get('submitterEmail');
    emailField2?.patchValue(this.aUser.email);
    const dogNotes = this.updateDogForm.get('dogNotes');
    dogNotes?.patchValue('');
    const memberId = this.updateDogForm.get('submitterMemberId');
    memberId?.patchValue(this.aUser.waId);
    const dogId = this.updateDogForm.get('dogId');
    dogId?.patchValue(this.dogId);
  }

  closeModal() {
    this.modalService.close();
  }

  closeThanksModal() {
    this.modalService.close();
    this.router.navigateByUrl('/search');
  }

  onUploadFinished(file: FileHolder) {
    // check the response
    this.customErrorMessage = '';
    if (file.serverResponse && file.serverResponse.response.status === 200) {
      let adPath = JSON.parse(file.serverResponse.response.body).filename;
      this.photoFilePath = adPath;
      this.filePaths.push(adPath);
      this.files.push(file);
      this.uploadFailed = false;
    } else {
      this.uploadFailed = true;
      if (file.serverResponse) {
        let errorMessage = JSON.parse(file.serverResponse.response.error).message;
        this.customErrorMessage = errorMessage;
      }
    }
  }

  onRemove(file: FileHolder) {
    if (file.serverResponse) {
      let adPath = JSON.parse(file.serverResponse.response.body).filename;
      this.photoFilePath = '';

      let fileIndex = this.filePaths.indexOf(adPath);
      if (fileIndex !== -1) {
        this.filePaths.splice(fileIndex, 1);
        this.files.splice(fileIndex, 1);
      }
    }
  }
  submitForm() {
    this.updateDogForm.markAllAsTouched();
    if (this.updateDogForm.valid) {
      this.showSpinner = true;
      this.updateDogForm.get('submitterName')?.enable();
      this.updateDogForm.get('submitterEmail')?.enable();

      this.dogService.postNewForm({
        committeeId: 71,
        senderWaId: this.aUser.waId,
        senderEmail: this.aUser.email,
        senderName: this.aUser.firstName + ' ' + this.aUser.lastName,
        formData: { ...this.updateDogForm.value, files: this.filePaths },
        formId: 'Dog Update'
      })
        .subscribe(() => {
            this.showSpinner = false;
            this.modalService.open('modal-update-thanks');
          },
          () => {
            this.showSpinner = false;
            this.updateDogForm.get('submitterName')?.disable();
            this.updateDogForm.get('submitterEmail')?.disable();
            alert('form failed to submit');
          });
    } else {
      alert('Form is incomplete.  Please fill out all required fields');
      return;
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.sub?.unsubscribe();
    this.unsubscribe2$.next();
    this.unsubscribe2$.complete();
    this.sub2?.unsubscribe();
  }

  getPedigreeBackgroundColor(dogOrDam: string, title: string): string {
    if (title == 'Unknown') {
      return "align-middle missing-color";
    } else {
      if (dogOrDam == 'Dog') {
        return "align-middle dog-color dog-color-corr";
      } else {
        return "align-middle dam-color dog-color-corr";
      }
    }
  }
  private createDogName(name: PedigreeNameRecord, health: string, callName: string): string {
    let dogName = name?.titleString;
    if ((callName != null) && (callName != '')) {
      dogName += ' (' + callName + ')';
    }
    if ((health != null) && (health != '')) {
      dogName += "<br>Health: " + health;
    }
    if (dogName == '') {
      dogName = 'Unknown';
    }
    return dogName;
  }

  getSireInfo() {
    if (this.selectedDogInfo?.sire_id === 0) {
      return 'Rescue Dog - Sire Not Available';
    } else {
      return this.selectedDogInfo?.sireInfo?.registered_name;
    }
  }
  getDamInfo() {
    if (this.selectedDogInfo?.dam_id === 0) {
      return 'Rescue Dog - Dam Not Available';
    } else {
      return this.selectedDogInfo?.damInfo?.registered_name;
    }
  }

  getBreederInfo() {
    if (this.selectedDogInfo?.hasOwnProperty('breeder')) {
      return this.selectedDogInfo?.breeder?.breeder_name;
    } else {
      return 'Rescue Dog - Breeder Not Provided';
    }
  }

  pedigreeChange() {
    this.pedigreePanelOpen = !this.pedigreePanelOpen;
    this.showHidePedigreeButtonName = this.pedigreePanelOpen ? 'Hide Pedigree' : 'Show Pedigree';
  }

  updateHealthOptions() {
    const selectedCondition = this.updateDogForm.controls['healthCategory'].value;
    const conditionArray = this.healthCategories.health?.filter((acat) => acat.title === selectedCondition);
    if (conditionArray?.length === 1) {
      const condition = conditionArray[0];
      this.healthCategoryOptionNames = condition.options?.map((acond) => acond.title);
    }
  }

  updateHealthOptionsOther() {
    const selectedCondition = this.updateDogForm.controls['healthCategory'].value;
    const conditionArray = this.healthCategories.health?.filter((acat) => acat.title === selectedCondition);
    if (conditionArray?.length === 1) {
      const condition = conditionArray[0];
      const selectedConditionOption = this.updateDogForm.controls['healthCategoryOption'].value;
      const conditionOptionArray = condition?.options?.filter((acat) => acat.title === selectedConditionOption);
      if (conditionOptionArray?.length === 1) {
        const option = conditionOptionArray[0];
        if (option.isOther) {
          this.updateDogForm.controls['otherDetails'].enable();
          this.updateDogForm.controls['otherDetails'].setValidators([Validators.required,Validators.maxLength(256)]);
        } else {
          this.updateDogForm.controls['otherDetails'].disable();
          this.updateDogForm.controls['otherDetails'].setValue('');
          this.updateDogForm.controls['otherDetails'].clearValidators();
        }
        this.updateDogForm.controls['otherDetails'].updateValueAndValidity();
      }
    }
  }

  selectedActionChanged() {
    const selectedCondition = this.updateDogForm.controls['other'].value ||
      this.updateDogForm.controls['titles'].value ||
      this.updateDogForm.controls['sireDam'].value ||
      this.updateDogForm.controls['callName'].value ||
      this.updateDogForm.controls['health'].value;
    if (selectedCondition) {
      this.updateDogForm.controls['dogNotes'].setValidators(Validators.required);
    } else {
      this.updateDogForm.controls['dogNotes'].clearValidators();
    }
    this.updateDogForm.controls['dogNotes'].updateValueAndValidity();
  }
  healthConditionChanged() {
    const selectedCondition = this.updateDogForm.controls['addHealth'].value;
    if (selectedCondition) {
      this.updateDogForm.controls['healthCategory'].enable();
      this.updateDogForm.controls['healthCategoryOption'].enable();
      this.updateDogForm.controls['healthConditionPublic'].enable();
      this.updateDogForm.controls['death'].enable();
      this.updateDogForm.controls['healthCategory'].setValidators(Validators.required);
      this.updateDogForm.controls['healthCategoryOption'].setValidators(Validators.required);
    } else {
      this.updateDogForm.controls['healthCategory'].disable();
      this.updateDogForm.controls['healthCategoryOption'].disable();
      this.updateDogForm.controls['healthConditionPublic'].disable();
      this.updateDogForm.controls['death'].disable();
      this.updateDogForm.controls['healthCategory'].setValue('');
      this.updateDogForm.controls['healthCategoryOption'].setValue('');
      this.updateDogForm.controls['healthCategory'].clearValidators();
      this.updateDogForm.controls['healthCategoryOption'].clearValidators();
    }
    this.updateDogForm.controls['healthCategory'].updateValueAndValidity();
    this.updateDogForm.controls['healthCategoryOption'].updateValueAndValidity();
    this.updateDogForm.controls['healthConditionPublic'].updateValueAndValidity();
    this.updateDogForm.controls['death'].updateValueAndValidity();
  }
  dateOfDeathChanged() {
    const selectedOption = this.updateDogForm.controls['dod'].value;
    if (selectedOption) {
      this.updateDogForm.controls['dateOfDeath'].enable();
      this.updateDogForm.controls['dateOfDeath'].setValidators([Validators.required, dateValidator()]);
    } else {
      this.updateDogForm.controls['dateOfDeath'].disable();
      this.updateDogForm.controls['dateOfDeath'].setValue('');
      this.updateDogForm.controls['dateOfDeath'].clearValidators();
    }
    this.updateDogForm.controls['dateOfDeath'].updateValueAndValidity();
  }

  dateOfBirthChanged() {
    const selectedOption = this.updateDogForm.controls['dob'].value;
    if (selectedOption) {
      this.updateDogForm.controls['dateOfBirth'].enable();
      this.updateDogForm.controls['dateOfBirth'].setValidators([Validators.required, dateValidator()]);
    } else {
      this.updateDogForm.controls['dateOfBirth'].disable();
      this.updateDogForm.controls['dateOfBirth'].setValue('');
      this.updateDogForm.controls['dateOfBirth'].clearValidators();
    }
    this.updateDogForm.controls['dateOfBirth'].updateValueAndValidity();
  }

  isDodDisabled() {
    return  !this.updateDogForm.controls['dod'].value;
  }
  isDobDisabled() {
    return  !this.updateDogForm.controls['dob'].value;
  }
}
